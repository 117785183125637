import React, { useState } from 'react';
import './Admin.css';
import AdminMessages from '../components/AdminMessages';

function Admin() {
  const [activeTab, setActiveTab] = useState('ateliers');

  const renderContent = () => {
    
        return <AdminMessages />;
    
  };

  return (
    <div id="admin">
      <h1>BACKROOM</h1>
      <div className="admin-tabs">
        <button className={`${activeTab == 'messages' ? 'active' : '' }`} onClick={() => setActiveTab('messages')}>Messages</button>
       
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Admin;
