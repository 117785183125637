function formatImperial(valueInMeters) {
    const totalInches = valueInMeters / 0.0254; // Convert meters to inches
    const feet = Math.floor(totalInches / 12); // Get the whole feet
    const inches = (totalInches % 12).toFixed(2); // Get the remaining inches
    return `${feet}' ${inches}"`; // Format as feet and inches
  }

  function unitToSymbol(unit) {
    switch (unit) {
      case "meters":
        return "m";
      case "centimeters":
        return "cm";
      case "feet":
        return "ft";
      case "inches":
        return "in";
      default:
        return "";
    }
  }

  export { formatImperial, unitToSymbol };