import React, { useState, useEffect } from 'react';
import { db, collection, onSnapshot, deleteDoc, doc } from '../firebase'; // Adjust the path as necessary
import { getDocs } from 'firebase/firestore';
import './AdminMessages.css';
const AdminMessages = () => {
  const [messages, setMessages] = useState([]);

  const [expandedMessageId, setExpandedMessageId] = useState(null);

  const toggleExpand = (id) => {
    setExpandedMessageId(expandedMessageId === id ? null : id);
  };

  useEffect(  () => {
    const fetchMessages = async () => {
      console.log('fetching messages');
      const messagesCollection = collection(db, 'contactSubmissions'); // Replace with the actual collection name
      console.log('messagesCollection', messagesCollection);
      const querySnapshot = await getDocs(messagesCollection);
      console.log('querySnapshot', querySnapshot);
      const messages = [];
      querySnapshot.forEach((doc) => {
        const timestamp = doc.data().timestamp;
        const date = new Date(timestamp.seconds * 1000).toLocaleDateString();
        const message ={ id: doc.id, ...doc.data(), date }; 
        messages.push(message);
      });
      messages.sort((a, b) => b.date - a.date);
      setMessages(messages);
    }
    fetchMessages().catch(console.error);

   
  }, []);

  const handleDelete = async (id) => {
    await deleteDoc(doc(db, 'contactSubmissions', id)); // Replace with the actual collection name
    const updatedMessages = messages.filter((message) => message.id !== id);
    setMessages(updatedMessages);
  };
  
  return (
    <div className='admin-messages'>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Name</th>
            <th>Email</th>
            <th>Reason</th>
            <th>Actions</th>
          </tr>
        </thead>
          {messages.map((message) => (
            <tbody  key={message.id}>

            <tr>
              <td>{message.date}</td>
              <td>{message.name}</td>
              <td>{message.email}</td>
              <td>{message.reason}</td>
              <td>
                <button onClick={() => toggleExpand(message.id)}>View</button>
                <button onClick={()=> window.open(`mailto:${message.email}&subject=Re: ${encodeURIComponent(`Corde & Ecorce: ${message.reason}`)}&body=${encodeURIComponent(message.message)}`)}>Reply</button>
                <button onClick={() => handleDelete(message.id)}>Delete</button>
              </td>
            </tr>
            <tr className={expandedMessageId === message.id ? 'expanded' : 'collapsed'}>
              <td colSpan="5">
                <pre>{message.message}</pre>
              </td>
            </tr>
            </tbody>

          ))}

      
      </table>
     
    </div>
  );
};

export default AdminMessages;
