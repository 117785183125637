// src/components/Header.js
import {React,useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/logo.svg'; // Place your SVG logo in this path
import WhoAmI from './WhoAmI';
import { Helmet } from "react-helmet";
/* improt routing so we can listen to changes in the URL */
import { useLocation } from 'react-router-dom';
function Header() {
    const location = useLocation();
   const [isAdminPath, setIsAdminPath] = useState(window.location.pathname.includes('admin'));
   const [pathTitles, setPathTitles] = useState({
    '/': '',
    '/ateliers': 'Ateliers ',
    '/creations': 'Créations ',
    '/outils': 'Outils ',
    '/contact': 'Contact ',
    '/admin': 'Admin ',
    });
    const [path, setPath] = useState(`/${window.location.pathname.split('/').pop()}`);
    useEffect(() => {
        setIsAdminPath(location.pathname.includes('admin'));
        setPath(`/${window.location.pathname.split('/').pop()}`);
    }, [location]);

    

  return (
    <>
    {!isAdminPath && <Helmet>
            <title>{pathTitles[path]}Corde & Écorce</title>            
            <meta name="description" content="Découvrez Corde et Écorce : artisanat naturel, savoir-faire traditionnels, créations uniques et ateliers inspirés des techniques ancestrales." />
            <meta name="keywords" content="artisanat, savoir-faire, corde, écorce, ateliers, feu primitif, noeuds, créations uniques" />
            <meta property="og:title" content="Corde et Écorce - Artisanat Naturel" />
            <meta property="og:description" content="Un projet qui célèbre les savoir-faire anciens et les matériaux naturels à travers des créations uniques et des ateliers." />
            <meta property="og:image" content={`${logo}`} />
            <meta property="og:url" content="https://francois-lafortune.art" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Corde et Écorce - Artisanat Naturel" />
            <meta name="twitter:description" content="Un projet qui célèbre les savoir-faire anciens et les matériaux naturels à travers des créations uniques et des ateliers." />
            <meta name="twitter:image" content={logo}/>
        </Helmet>
    }
    <header className="header">
      <Link to="/"><img src={logo} alt="Crode & Ecorce Logo" className="logo" /></Link>
      { isAdminPath && <WhoAmI /> }
      <nav>
        <ul>
          <li>
            <Link to="/ateliers">Ateliers</Link>
          </li>
          <li>
            <Link to="/creations">Creations</Link>
          </li>
          <li>
            <Link to="/outils">Outils</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
    </header>
    </>
  );
}

export default Header;
