// src/pages/Contact.js
import React, { useState,useRef } from 'react';
import {serverTimestamp} from 'firebase/firestore';
import { db,collection,addDoc } from '../firebase'; // Adjust the path if necessary
import ReCAPTCHA from 'react-google-recaptcha';
import './Contact.css';

function Contact() {
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    reason: '',
    message: '',
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Envoi en cours...');
  
    // Execute the reCAPTCHA and get the token
    const recaptchaValue = await recaptchaRef.current.executeAsync();
    console.log('reCAPTCHA token:', recaptchaValue);
    recaptchaRef.current.reset(); // Reset reCAPTCHA for next submission
  
    if (!recaptchaValue) {
      setStatus('Veuillez compléter le reCAPTCHA.');
      return;
    }
  
    const submission = {
      ...formData,
      timestamp: serverTimestamp(),
      recaptchaToken: recaptchaValue,
    };
    const colRef =  collection(db,'contactSubmissions');
    try{
        console.log('addDoc',submission)
    await addDoc(colRef, submission);
    setStatus('Message reçu avec succès !');
    setFormData({
      name: '',
      email: '',
      phone: '',
      reason: '',
      message:'',
    }
    );
    }catch(error){
        console.error('Error writing to Firestore:', error);
        setStatus('Une erreur est survenue. Veuillez réessayer plus tard.');
    }
     
  };
  

  return (
    <main className="main-content">
      <h1>Contact</h1>
      <p>Que ce soit pour une création sur mesure, un projet lié aux savoir-faire traditionnels, ou une idée d’atelier, je suis toujours curieux de découvrir de nouvelles possibilités.</p>

      <p>Si vous avez une proposition ou une question en lien avec mes compétences et mon univers, n’hésitez pas à me contacter. Je répondrai avec plaisir !</p>
      
      <form className="contact-form" onSubmit={handleSubmit}>
        <label>
          <span>Nom:</span>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
            <span>Email:</span>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
            <span>Tel:</span>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            
          />
        </label>
        <label>
            <span>Raison:</span>
          <input
            type="text"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
            required
          />
        </label>
        <label>
            <span>Message:</span>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          />
        </label>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LfJmJIqAAAAAJ7ZcO-EHZqstM4-mOkPli3i6rTS"
          size="invisible"
        />
        <button type="submit">Envoyer</button>
      </form>
      {status && <p>{status}</p>}
    </main>
  );
}

export default Contact;
