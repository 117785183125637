import React, { useState } from "react";
import './Calculator.css';
import { unitToSymbol,formatImperial } from "./helpers";
const CoverageCalculator = ( ) => {
  const [surface, setSurface] = useState({ length: 0, width: 0, height: 0 });
  const [material, setMaterial] = useState({ width: 0, spacing: 0 });
  const [unit, setUnit] = useState("meters");
  const [result, setResult] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const unitConversionFactor = {
    meters: 1,
    centimeters: 0.01,
    feet: 0.3048,
    inches: 0.0254,
  };

  const calculateMaterial = () => {
    const conversionFactor = unitConversionFactor[unit];

    // Convert all inputs to meters for calculations
    const length = surface.length * conversionFactor;
    const width = surface.width * conversionFactor;
    const height = surface.height * conversionFactor;
    const materialWidth = material.width * conversionFactor;
    const materialSpacing = material.spacing * conversionFactor;

    const surfaceArea =
      2 * (length * height) +
      2 * (width * height) +
      length * width; // Square meters

    const materialNeededPerLayer = length / (materialWidth + materialSpacing);
    const totalMaterialNeeded = materialNeededPerLayer * height;

    setResult({
      surfaceArea: surfaceArea.toFixed(2),
      materialNeeded: totalMaterialNeeded.toFixed(2),
    });
  };

  

  return (
    <div className={`calculator ${expanded ? 'expanded' : ''}`}>
      <h2>Calculateur de couverture de corde/bandes</h2>
      <p className="explain">
        Utilisez cet outil pour calculer la quantité de corde ou de bandes
        nécessaires pour couvrir une surface donnée.
      </p>
      <button className="expander" onClick={() => setExpanded(!expanded)}>{expanded ? 'Diminuer' : 'Utiliser'}</button>

      <div className={`input ${expanded?'expanded' : 'hidden'}`}>

      <label>Unité:
      <select value={unit} onChange={(e) => setUnit(e.target.value)}>
        <option value="meters">Mètres</option>
        <option value="centimeters">Centimètres</option>
        <option value="feet">Pieds</option>
        <option value="inches">Pouces</option>
      </select>
      </label>
      <p>Entrez les dimensions de la surface à couvrir:</p>
      <div>
        <label>
          Longueur ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={surface.length}
            onChange={(e) =>
              setSurface({ ...surface, length: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Largeur ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={surface.width}
            onChange={(e) =>
              setSurface({ ...surface, width: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Hauteur ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={surface.height}
            onChange={(e) =>
              setSurface({ ...surface, height: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <p>Entrez les propriétés de la corde ou des bandes:</p>
      <div>
        <label>
          Largeur/Épaisseur ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={material.width}
            onChange={(e) =>
              setMaterial({ ...material, width: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Espacement entre les tours ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={material.spacing}
            onChange={(e) =>
              setMaterial({ ...material, spacing: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <button
       
        onClick={calculateMaterial}
      >
        Calculer
      </button>
      </div>
      {result && expanded && (
        <div className="result">
          <h3>Résultats:</h3>
          <p>
            Surface Totale: <strong>{result.surfaceArea} mètres carrés</strong>
          </p>
          <p>
  Matériel Nécessaire:{" "}
  <strong>
    {unit === "feet" || unit === "inches"
      ? formatImperial(result.materialNeeded)
      : `${result.materialNeeded} ${unitToSymbol(unit)}`}
  </strong>
</p>
<p>
  <em>
    Remarque: Ajoutez 10% à 20% de matériel supplémentaire pour tenir compte des
    croisements ou des erreurs, soit entre{" "}
    {unit === "feet" || unit === "inches"
      ? `${formatImperial(result.materialNeeded * 0.1)} et ${formatImperial(
          result.materialNeeded * 0.2
        )}`
      : `${(result.materialNeeded * 0.1).toFixed(2)} ${unitToSymbol(
          unit
        )} et ${(result.materialNeeded * 0.2).toFixed(2)} ${unitToSymbol(
          unit
        )}`}
  </em>
</p>
        </div>
      )}
    </div>
  );
};

export default CoverageCalculator;
