import React, { useEffect, useRef, useState } from "react";
import { KnotUI } from "./knot_ui";
import './Knotter.css';
import jsPDF from "jspdf";
import Symbola from './Symbola.ttf';
import logopng from '../../assets/logo.png';
import watermark from '../../assets/watermark.png';
const Knotter = () => {
  const canvasRef = useRef(null);
  const knotUIRef = useRef(null);
  const [expanded, setExpanded] = useState(false);

  // État interne pour les paramètres
  
  const [params, setParams] = useState({
    parts: 11,
    bights: 7,
    coding: "\\/",
    width: 600,
    height: 600,
    strandWidth: 10,
    overColor: "#cc0000",
    underColor: "#990000",
    miterColor: "#ff0000",
    orient: "vertical",
    swapBightOrder: true,
    showColors: false,
    sobre: false,
    hcColors: {},
  });

  const [runList, setRunList] = useState([]);
  const [currentStep, setCurrentStep] = useState();
  const [hasInvalidParams, setHasInvalidParams] = useState(false);
  // Initialiser ou mettre à jour l'instance de KnotUI lorsque les paramètres changent
  useEffect(() => {
    const isValidParams = gcdCheck(params.parts, params.bights) === 1;


    if (!isValidParams) {
      console.warn("Invalid parameters: gcd(parts, bights) !== 1");
      setRunList([]); // Clear the run list if parameters are invalid
      setHasInvalidParams(true);
      return; // Skip updating KnotUI
    }else{
      setHasInvalidParams(false);
    }
    
    if (canvasRef.current) {
      console.log("Updating KnotUI with params:", params);

      const knotParams = {
        parts: params.parts,
        bights: params.bights,
        coding: params.coding,
        width: params.width,
        height: params.height,
        strand_width: params.strandWidth,
        over_color: params.overColor,
        under_color: params.underColor,
        miter_color: params.miterColor,
        orient: params.orient,
        swap_bight_order: params.swapBightOrder,
        show_colors: params.showColors,
        sobre: params.sobre,
        ...params.hcColors,
      };

      if (!knotUIRef.current) {
        knotUIRef.current = new KnotUI(canvasRef.current, knotParams);
      } else {
        knotUIRef.current.updateParams(knotParams);
      }

      // Extraire la liste des cycles depuis `knotUIRef` et mettre à jour l'état
      const knot = knotUIRef.current?.knot;
      console.log("Updated knot state:", knot);

      if (knot && knot.half_cycles) {
        const formattedRunList = knot.half_cycles.map((hc) => {
          return `${hc.from_pin} → ${hc.to_pin} 〰 ${hc.run_list_str().replace(/U/gm,'↓').replace(/O/gm,'↑')}`; 
        });
        if(isNaN(currentStep)){
          setCurrentStep(knot.half_cycles.length-1);
        }
        setRunList(formattedRunList);
        if (currentStep >= knot.half_cycles.length) {
          console.warn("Adjusting currentStep to valid range.");
          setCurrentStep(Math.max(0, knot.half_cycles.length - 1));
      }
        if(Object.keys(params.hcColors).length == 0 && params.overColor){
          // Set default colors
          console.log('setting default colors', params.overColor);
        }
      }else{
        console.error("Invalid knot state detected.");

      }

      // Dessiner l'étape actuelle
      if (knotUIRef.current?.diagram) {
        knotUIRef.current.diagram.clear(knotUIRef.current.ctx);
        knotUIRef.current.diagram.draw_hc(
          knotUIRef.current.ctx,
          currentStep,
          1
        );
      }
    }
  }, [params, currentStep]);

  const updateParam = (key, value) => {
    setParams((prevParams) => ({ ...prevParams, [key]: value }));
  };

  const setHCColor = (index, color) => {
    setParams((prevParams) => ({
      ...prevParams,
      hcColors: { ...prevParams.hcColors, [`hc${index}_color`]: color },
    }));
  };

  const downloadPDF = async () => {
    const pdf = new jsPDF();
    const canvas = canvasRef.current;
  
    if (!canvas) {
      alert("Canvas not available for export.");
      return;
    }
  
    // Font registration
    pdf.addFont(Symbola, "Symbola", "normal");
    pdf.setFont("Symbola");
  
    // 1. Add Front Page with Logo
    const logoURL = "/path/to/logo.png"; // Replace with the actual path to your logo
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const logoWidth = 186; // Adjust logo size
    const logoHeight = 87;
    const copystring = "© 2025 François Lafortune - Corde & Ecorce - https://francois-lafortune.art";
    pdf.addImage(
      logopng,
      "PNG",
      (pageWidth - logoWidth) / 2,
      (pageHeight - logoHeight) / 2,
      logoWidth,
      logoHeight
    );
  
    pdf.setFontSize(12);
    pdf.text(copystring, pageWidth / 2, pageHeight - 10, { align: "center" });
  
    pdf.addPage();
  
    // 2. Add the Diagram with Watermark
    const imgData = canvas.toDataURL("image/png");
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
  
    // Set custom page size to match the diagram's dimensions
    pdf.setPage(2);
    pdf.internal.pageSize.width = imgWidth;
    pdf.internal.pageSize.height = imgHeight;
  
    // Add the diagram image
    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
  
    // Add watermark to the bottom-right corner
    const watermarkURL = watermark; // Replace with the actual path to your watermark
    const watermarkWidth = 50; // Adjust watermark size
    const watermarkHeight = 50;
    pdf.addImage(
      watermarkURL,
      "PNG",
      imgWidth - watermarkWidth - 10, // 10px margin from the right
      imgHeight - watermarkHeight - 10, // 10px margin from the bottom
      watermarkWidth,
      watermarkHeight
    );
  
    // Add footer
    pdf.setFontSize(10);
    pdf.text(copystring,  imgWidth/2, imgHeight - 5, { align: "center" });
  
    // Reset page size for the run list
    pdf.addPage();
    pdf.internal.pageSize.width = 210; // A4 width in mm
    pdf.internal.pageSize.height = 297; // A4 height in mm
  
    // 3. Add the Run List to the Third Page
    pdf.setFontSize(14);
    pdf.text("Liste des cycles:", 10, 10);
  
    // Add the run list
    const marginLeft = 10;
    const marginTop = 20;
    const lineHeight = 7;
    let yOffset = marginTop;
  
    runList.forEach((item, index) => {
      if (yOffset + lineHeight > 297 - 10) { // If near bottom of page, add a new page
        pdf.addPage();
        pdf.setFontSize(10);
        pdf.text(copystring, pageWidth/2, pageHeight - 5, { align: "center" });
        yOffset = marginTop;
      }
      pdf.text(`${index + 1}: ${item}`, marginLeft, yOffset);
      yOffset += lineHeight;
    });
  
    // Add footer to the last page
    pdf.setFontSize(10);
    pdf.text(copystring,  pageWidth/2, pageHeight - 5, { align: "center" });
  
    // Save the PDF
    const diagramName = `B${params.bights}L${params.parts}-${params.width}X${params.height}-diagram`;
    pdf.save(`${diagramName}.pdf`);
  };
  
  
  
  

  const goToNextStep = () => {
    const knot = knotUIRef.current?.knot;
    if (knot && currentStep < knot.half_cycles.length) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

function darkenColor(color, factor) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  const newR = Math.max(0, Math.min(255, Math.round(r * factor)));
  const newG = Math.max(0, Math.min(255, Math.round(g * factor)));
  const newB = Math.max(0, Math.min(255, Math.round(b * factor)));
  return `#${newR.toString(16)}${newG.toString(16)}${newB.toString(16)}`;
}

function gcdCheck(x,y) {
  console.log("Checking GCD for:", x, y);
  if( x < y ) return gcdCheck(y, x);
  if( y === 0 ) return x;
  return gcdCheck(y, x%y);
}
  return (
    <div className={`calculator ${expanded ? 'expanded' : ''}`}>
      <h2>Guide de Bonnet Turc</h2>
      <p>Cet outil vous permet de créer un guide de bonnet turc pour vos projets de corde et de cordonnerie.</p>
      <p>Pour un outil plus avancé voir <a href="https://www.freakinsweetapps.com/knots/knotgrid/advanced.html" target="_blank">https://www.freakinsweetapps.com/knots/knotgrid/advanced.html</a></p>
      <button className="expander" onClick={() => setExpanded(!expanded)}>{expanded ? 'Diminuer' : 'Utiliser'}</button>
      <div className={`input ${expanded?'expanded' : 'hidden'}`}>
      
        <div >
          <label>Largeur:
            <input type="number" step="10" min="200" max="2000" value={params.width} onChange={(e) => updateParam("width", parseInt(e.target.value, 10))} />
          </label>
          <label>Hauteur:
            <input type="number" step="10" min="200" max="2000" value={params.height} onChange={(e) => updateParam("height", parseInt(e.target.value, 10))} />
          </label>
        
        <label>
          Lignes :
          <input
            type="number"
            value={params.parts}
            onChange={(e) => updateParam("parts", parseInt(e.target.value, 10))}
          />
          
        </label>
        <label>
          Boucles :
          <input
            type="number"
            value={params.bights}

            onChange={(e) => updateParam("bights", parseInt(e.target.value, 10))}
          />
        </label>
        {hasInvalidParams && (
  <p className="error">
  ⚠️ Les paramètres sélectionnés ne permettent pas de créer un bonnet turc valide. 
  Le nombre de lignes ("Lignes") et le nombre de boucles ("Boucles") doivent être 
  tels qu'ils n'ont pas de <a href="https://fr.wikipedia.org/wiki/Factorisation" target="_blank">facteur commun</a> autre que 1 (par exemple, 5 et 7).
</p>
)}
        <label>
          Largeur de brin :
          <input
            type="number"
            value={params.strandWidth}
            onChange={(e) =>
              updateParam("strandWidth", parseInt(e.target.value, 10))
            }
          />
        </label>
        <label>
          Codage :
          <input
            type="text"
            value={params.coding}
            onChange={(e) => updateParam("coding", e.target.value)}
          />
        </label>
        <label>
          Couleur du dessus :
          <input
            type="color"
            value={params.overColor}
            onChange={(e) => updateParam("overColor", e.target.value)}
          />
        </label>
        <label>
          Couleur du dessous :
          <input
            type="color"
            value={params.underColor}
            onChange={(e) => updateParam("underColor", e.target.value)}
          />
        </label>
        <label>
          Couleur des angles :
          <input
            type="color"
            value={params.miterColor}
            onChange={(e) => updateParam("miterColor", e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>Configurer couleurs des demi-cycles
          <input
            type="checkbox"
            checked={params.showColors}
            onChange={(e) => updateParam("showColors", e.target.checked)}
          />
        </label>
        <div className="colorgrid">
        { params.showColors && runList.map((_, index) => (
          <div key={index}>
            <label>
              {index + 1}
              <input
                type="color"
                value={params.hcColors[`hc${index}_color`] || darkenColor(params.overColor, index / runList.length)}
                onChange={(e) => setHCColor(index, e.target.value)}
              />
            </label>
          </div>
        ))}
        </div>
      </div>
      <div className="stepnav">
        <button         className="stepper"
 onClick={goToPreviousStep} disabled={currentStep === 0}>
          &larr;
        </button>
        <div className="current-step">Étape {currentStep + 1}</div>
        <button
        className="stepper"
          onClick={goToNextStep}
          disabled={
            knotUIRef.current?.knot &&
            currentStep >= knotUIRef.current.knot.half_cycles.length - 1
          }
        >
        &rarr;
        </button>
      </div>
      <canvas ref={canvasRef} width={params.width} height={params.height} style={{opacity:hasInvalidParams ? 0 : 1}}/>
      <div>
        <h2>Liste des cycles</h2>
        <ol className="steps-list">
        {runList.length > 0 ? (
          runList.map((item, index) => <li key={index}>{item}</li>)
        ) : (
          <p>Pas de données disponibles.</p>
        )}
        </ol>
        <button onClick={downloadPDF} className="download-pdf">
        Télécharger le PDF
    </button>
      </div>
    <br/>
   
    <em>Cet outil est une reproduction de l'outil "Turkshead Tying" par John Allwine (<a hred="https://www.allwinedesigns.com/blog" target="_blank">www.allwinedesigns.com</a>) via <a href="https://igkt.net/knotting-basics/knotting-tools" target="_blank">https://igkt.net/knotting-basics/knotting-tools</a> .</em>
    </div>
    </div>
  );
};

export default Knotter;
