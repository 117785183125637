import {React, useState,useEffect} from 'react';
import { getAuth, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import './WhoAmI.css';
function WhoAmI() {
  const auth = getAuth();
  const [user,setUser] = useState(auth.currentUser);
  const [hasLoginForm, setHasLoginForm] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isVisible, setIsVisible] = useState(window.location.pathname.includes('admin'));
  const handleLogout = () => {
    signOut(auth);
  };
 

  async function  dispatchLoginClick() {
    if(!hasLoginForm){
      setHasLoginForm(true);
      return;
    }else{
      console.log('loggin in')
      const userCredential = await signInWithEmailAndPassword(auth, email,password);
      const user = userCredential.user;
      console.log(user)
      setUser(user);
      setEmail('');
      setPassword('');
      setHasLoginForm(false);
    }
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  });
  // listen to route changes
 
  if (!isVisible) {
    return null;
  }
  if (user) {
    return (
      <div className="whoami">
        <img className="avatar" src={user.photoURL} alt="User Avatar" style={{ width: '50px', borderRadius: '50%' }} />
        <p className="email">{user.email}</p>
        <button className="logout-button" onClick={handleLogout}>🔑</button>
      </div>
    );
  } else {
    return <div className="whoami">
      <form className={`login-form ${hasLoginForm ? 'visible' : 'hidden'}`}>
      <input className="login-email-input" type="email" placeholder="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      <input className="login-password-input" type="password" placeholder="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </form>
      <button className="login-button" onClick={() => dispatchLoginClick()}>🔒</button>
      
      </div>;
  }
}

export default WhoAmI;
