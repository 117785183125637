// src/pages/Creations.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import collage from '../assets/collage.jpg'; // Place your image in this path
function Home() {
  return (
   
    <main className="main-content">
        <h1>Bienvenue</h1>
      <p>Corde et Écorce, c’est ma façon de redonner vie aux savoir-faire anciens et de travailler avec des matériaux naturels. Je crée des objets uniques, j’explore des techniques traditionnelles et je conçois des outils pour partager ces connaissances.</p>
      <p>C’est un projet en constante évolution, guidé par ma curiosité et mon envie de transmettre.</p>
      <img src={collage} alt="Collage of pictures" />
      
      <p>Je suis un artisan passionné par les savoir-faire anciens et les matériaux naturels. Mon parcours est marqué par une curiosité constante : apprendre par la pratique, expérimenter avec ce que la nature offre, et approfondir des compétences traditionnelles comme le travail de la corde, l’écorce, ou encore des techniques primitives.  </p>

<p>Au fil des années, j’ai exploré des pratiques qui exigent patience, minutie et respect des ressources naturelles. J’ai aussi acquis une solide compréhension de certains savoirs liés à la survie et aux méthodes ancestrales, des connaissances que je continue d’affiner à travers mes expériences personnelles et mes projets.  </p>

<p>Je ne prétends pas tout savoir, mais chaque étape de mon parcours est guidée par une envie sincère de comprendre et de transmettre. Mon approche est avant tout basée sur l’apprentissage constant, le partage et une grande attention portée aux détails.  </p>

    </main>
        
   
  );
}

export default Home;
