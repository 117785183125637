import React, { useState } from "react";
import './Calculator.css';
import { unitToSymbol,formatImperial } from "./helpers";

const RopeTwistCalculator = () => {
  const [inputs, setInputs] = useState({
    ropeLength: 0,
    stringDiameter: 0,
    compressionFactor: 10,
    strands: 2,
  });
  const [unit, setUnit] = useState("meters");
  const [result, setResult] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const unitConversionFactor = {
    meters: 1,
    centimeters: 0.01,
    feet: 0.3048,
    inches: 0.0254,
  };

  const calculateStringLength = () => {
    const conversionFactor = unitConversionFactor[unit];
    const ropeLength = inputs.ropeLength * conversionFactor; // Convert rope length
    const stringDiameter = inputs.stringDiameter * conversionFactor; // Convert string diameter
  
    // Calculate effective rope diameter with compression
    const ropeDiameter = stringDiameter * inputs.strands * 0.85;
  
    // Assume a standard number of twists per unit length (e.g., 10 twists per meter)
    const twistsPerUnitLength = 10; // You can make this user-configurable if needed
  
    // Calculate the effective length of one strand
    const strandLength = Math.sqrt(
      Math.pow(ropeLength, 2) +
        Math.pow(Math.PI * ropeDiameter * twistsPerUnitLength * ropeLength, 2)
    );
  
    // Total string length required
    const totalStringLength = strandLength * inputs.strands;
  
    setResult({
      totalStringLength: totalStringLength.toFixed(2),
    });
  };
  
  
  return (
    <div className={`calculator ${expanded ? 'expanded' : ''}`}>
      <h2>Calculateur de ficelle pour corde torsadée</h2>
      <p className="explain">
        Utilisez cet outil pour calculer la longueur de ficelle nécessaire pour
        creer une corde torsadée de plusieurs brins d'une longueur donnée.
    </p>
    <button className="expander" onClick={() => setExpanded(!expanded)}>{expanded ? 'Diminuer' : 'Utiliser'}</button>
      
    <div className={`input ${expanded?'expanded' : 'hidden'}`}>

      <label>Unité:
      <select value={unit} onChange={(e) => setUnit(e.target.value)}>
        <option value="meters">Mètres</option>
        <option value="centimeters">Centimètres</option>
        <option value="feet">Pieds</option>
        <option value="inches">Pouces</option>
      </select>
      </label>
      <p>Entrez les détails de votre corde et de votre ficelle:</p>
      <div>
        <label>
          Longueur de la corde ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={inputs.ropeLength}
            onChange={(e) =>
              setInputs({ ...inputs, ropeLength: parseFloat(e.target.value) })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Diamètre de la ficelle ({unitToSymbol(unit)}):{" "}
          <input
            type="number"
            value={inputs.stringDiameter}
            onChange={(e) =>
              setInputs({
                ...inputs,
                stringDiameter: parseFloat(e.target.value),
              })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Facteur de compression ({inputs.compressionFactor}%):{" "}
          <input
            type="range"
            min="0"
            max="50"
            step="1"
            value={inputs.compressionFactor}
            onChange={(e) =>
              setInputs({
                ...inputs,
                compressionFactor: parseFloat(e.target.value),
              })
            }
          />
        </label>
      </div>
      <div>
        <label>
          Nombre de brins:{" "}
          <select
            value={inputs.strands}
            onChange={(e) =>
              setInputs({ ...inputs, strands: parseInt(e.target.value, 10) })
            }
          >
            <option value={2}>2-brins</option>
            <option value={3}>3-brins</option>
            <option value={4}>4-brins</option>
          </select>
        </label>
      </div>
      <math xmlns="http://www.w3.org/1998/Math/MathML" display="block">
    <mi>L</mi><msub><mi>total</mi></msub>
    <mo>=</mo>
    <mi>N</mi>
    <mo>&#xD7;</mo>
    <msqrt>
      <msup>
        <mi>L</mi><msub><mi>cord</mi></msub><mn>2</mn>
      </msup>
      <mo>+</mo>
      <msup>
        <mfenced separators="">
          <mi>&#x3C0;</mi>
          <mo>&#xD7;</mo>
          <mi>D</mi>
          <mo>&#xD7;</mo>
          <mi>T</mi>
          <mo>&#xD7;</mo>
          <mi>L</mi><msub><mi>fic</mi></msub>
        </mfenced>
        <mn>2</mn>
      </msup>
    </msqrt>
  </math>
      <button
       
        onClick={calculateStringLength}
      >
        Calculer
      </button>
      </div>
      {result && expanded && (
        <div className="result">
          <h3>Résultats:</h3>
          <p>
  Longueur totale de la ficelle nécessaire:{" "}
  <strong>
    {unit === "feet" || unit === "inches"
      ? formatImperial(result.totalStringLength)
      : `${result.totalStringLength} ${unitToSymbol(unit)}`}
  </strong>
</p>



<p>
  <em>
    Remarque: Ajoutez 10% à 20% de ficelle supplémentaire pour tenir compte des
    erreurs, soit entre{" "}
    {unit === "feet" || unit === "inches"
      ? `${formatImperial(result.totalStringLength * 0.1)} et ${formatImperial(
          result.totalStringLength * 0.2
        )}`
      : `${(result.totalStringLength * 0.1).toFixed(2)} ${unitToSymbol(
          unit
        )} et ${(result.totalStringLength * 0.2).toFixed(2)} ${unitToSymbol(
          unit
        )}`}
  </em>
</p>

        </div>
      )}
    </div>
  );
};

export default RopeTwistCalculator;
