// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializeFirestore, getFirestore,collection, onSnapshot,addDoc , deleteDoc, doc } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD1YOnDwDLi9cfSAmpoFDJN6YwOQMK8Jok",
  authDomain: "corde-ecorce.firebaseapp.com",
  projectId: "corde-ecorce",
  storageBucket: "corde-ecorce.firebasestorage.app",
  messagingSenderId: "454538617594",
  appId: "1:454538617594:web:da41c4c02f80d8ef985f43",
  measurementId: "G-03NX2HXYKC"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

initializeFirestore(app,{
    experimentalAutoDetectLongPolling: true,
});
// Initialize Firestore
const db = getFirestore();

export { db,  collection, onSnapshot,addDoc, deleteDoc, doc,analytics, app  };