// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="footer">
     <div className="creator">Conçu avec ❤️ dans la région d’<a href="https://maps.app.goo.gl/v3yTfZy1DuohX1G57" target="_blank">Abitibi-Témiscamingue</a>, Québec.</div>
      <div className="legalese">&copy; 2025 François Lafortune ❖ Corde & Écorce ❖ Tous droits réservés ❖ <Link to="/legal">Mentions légales</Link></div>
    </footer>
  );
}

export default Footer;
