// src/pages/Creations.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Outils.css';
import RopeTwistCalculator from '../components/tools/RopeTwistCalculator';
import CoverageCalculator from '../components/tools/CoverageCalculator';
import Knotter from '../components/tools/Knotter';
function Outils() {
  return (
   
    <main className="main-content">
        <h1>Outils</h1>
      <p>
Cette section regroupe des calculatrices et outils conçus pour vous simplifier la vie dans vos projets d’artisanat. Que ce soit pour planifier vos créations ou mieux comprendre vos besoins en matériaux, ces outils sont là pour vous accompagner.</p>
      <Knotter />
      <CoverageCalculator  />
      <RopeTwistCalculator />
        <h2> Des idées d’outils ?</h2>
        <p>Si vous avez une idée d’outil qui pourrait être utile ou une suggestion pour améliorer ceux existants, je serais ravi de vous lire. <a href="/contact">N’hésitez pas à me partager vos besoins</a> !</p>
    </main>
        
   
  );
}

export default Outils;
