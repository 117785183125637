// src/pages/Creations.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Legal.css';

function Legal() {
  return (
    <main className="main-content">
      <div className="legal-paper">
      <h1>Politique de Confidentialité pour francois-lafortune.art</h1>
<p>Date d'entrée en vigueur : 2025-01-01</p>

<h1>Bienvenue sur francois-lafortune.art (le « Site »).</h1>
<p>Votre vie privée est importante pour nous. Cette politique de confidentialité décrit les types d'informations que nous collectons lorsque vous visitez notre Site et comment nous les utilisons.</p>

<h2>1. Informations que nous collectons</h2>
<h3>1.1. Données personnelles</h3>
<p>Nous ne collectons aucune information personnelle identifiable à moins que vous ne choisissiez de nous les fournir volontairement, par exemple en nous contactant via un formulaire ou en vous abonnant à une newsletter.</p>

<h3>1.2. Données de journal</h3>
<p>Comme de nombreux sites web, nous collectons des informations que votre navigateur envoie automatiquement lorsque vous visitez notre Site (« Données de journal »). Ces données peuvent inclure :</p>
<ul>
  <li>Votre adresse IP</li>
  <li>Le type et la version de votre navigateur</li>
  <li>Les pages que vous visitez sur notre Site</li>
  <li>La date et l'heure de votre visite</li>
  <li>Le temps passé sur ces pages</li>
  <li>Autres statistiques similaires</li>
</ul>

<h2>2. Utilisation des cookies</h2>
<p>Les cookies sont de petits fichiers texte stockés sur votre appareil pour améliorer votre expérience utilisateur. Nous pouvons utiliser des cookies pour :</p>
<ul>
  <li>Comprendre comment vous utilisez notre Site</li>
  <li>Personnaliser le contenu selon vos préférences</li>
</ul>
<p>Vous pouvez configurer votre navigateur pour refuser les cookies ou vous alerter lorsque des cookies sont envoyés. Cependant, certaines parties du Site peuvent ne pas fonctionner correctement sans cookies.</p>

<h2>3. Utilisation de vos informations</h2>
<p>Nous utilisons les informations collectées pour :</p>
<ul>
  <li>Maintenir et améliorer notre Site</li>
  <li>Répondre à vos questions ou commentaires si vous nous contactez</li>
  <li>Analyser l'utilisation du Site pour améliorer le contenu et les services</li>
</ul>

<h2>4. Partage de vos informations</h2>
<p>Nous ne vendons, n'échangeons ni ne louons vos informations personnelles à des tiers. Nous pouvons partager des informations agrégées non personnelles avec des tiers pour des fins d'analyse ou d'amélioration du Site.</p>

<h2>5. Sécurité des informations</h2>
<p>Nous mettons en œuvre des mesures raisonnables pour protéger vos informations. Cependant, aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sécurisée.</p>

<h2>6. Liens vers des sites tiers</h2>
<p>Notre Site peut contenir des liens vers d'autres sites qui ne sont pas exploités par nous. Nous n'avons aucun contrôle sur le contenu et les pratiques de ces sites et déclinons toute responsabilité quant à leurs politiques de confidentialité.</p>

<h2>7. Protection de la vie privée des enfants</h2>
<p>Notre Site n'est pas destiné aux enfants de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnelles auprès d'enfants.</p>

<h2>8. Modifications de cette politique de confidentialité</h2>
<p>Nous pouvons mettre à jour notre politique de confidentialité de temps en temps. Nous vous conseillons de consulter cette page régulièrement pour rester informé des changements.</p>

<h2>9. Contact</h2>
<p>Si vous avez des questions concernant cette politique de confidentialité, veuillez nous contacter via le <Link to="contact">formulaire de contact</Link> disponible sur notre Site.</p>
</div>
    </main>
  );
}

export default Legal;
