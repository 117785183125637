// src/pages/Creations.js
import React from 'react';
import './Creations.css';
import shelf from '../assets/shelf.jpg'; // Place your image in this path
function Creations() {
  return (
    <main className="main-content">
      <h1>Creations</h1>
      <p>
        Bientôt, cette section présentera mes réalisations : objets à vendre, idées artisanales, et contenus divers. En attendant, <a href="/contact">contactez-moi</a> pour toute idée ou projet.
      </p>
      <img src={shelf} alt="Placeholder" />
    </main>
  );
}

export default Creations;
